import { Form as FormikForm } from 'formik';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    gap: ${theme.layout.spacing(4)};
    padding: ${theme.layout.spacing(4)};

    width: 100vw;
    height: 100vh;

    overflow-y: auto;

    background-color: ${theme.palette.neutral[100]};

    color: ${theme.palette.text.main};
  `}
`;

export const Form = styled(FormikForm)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${theme.layout.columnGap(4)}

    section {
      display: flex;
      flex-direction: column;
      ${theme.layout.columnGap(2)}

      width: 100%;
      max-width: ${theme.layout.spacing(138)};
    }
  `}
`;

export const DefaultDataButton = styled.button`
  ${({ theme }) => css`
    align-self: flex-start;

    color: ${theme.palette.text.white};
    background-color: ${transparentize(0.3, theme.palette.secondary.main)};
    border: 1px solid ${theme.palette.secondary.main};

    border-radius: 0.75rem;

    padding: ${theme.layout.spacing(1)};
  `}
`;
