import { useEffect, useMemo, useState } from 'react';

import Wizard, { TWizardAction } from 'components/Wizard';
import { useContracts } from 'context/ContractsContext';

import { useLocale, useNavigation } from 'shared/presentation/contexts';
import { usePerformCheckin } from 'shared/presentation/hooks';

import ContractSelectionList from '../ContractSelectionList';
import Details from '../Details';
import Sign from '../Sign';
import Signed from '../Signed';

const ContractsWizard: React.FC = () => {
  const navigation = useNavigation();
  const {
    userId,
    selectedContract,
    setSelectedContract,
    termsAccepted,
    setTermsAccepted,
    currentStep,
    onStepChange,
    setSignature,
    signContract,
    hasCheckin,
    userContracts,
    getUserContracts,
  } = useContracts();
  const { t } = useLocale('translations');

  const blankCanvas = document.createElement('canvas');

  const [canSign, setCanSign] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);

  const [signatureCanvas, setSignatureCanvas] = useState<HTMLCanvasElement>();
  const [signatureClearCallback, setSignatureClearCallback] =
    useState<() => void>();

  useEffect(() => {
    if (currentStep === 3) {
      getUserContracts();
    }
  }, [currentStep, getUserContracts]);

  useEffect(() => {
    if (currentStep === 0 && selectedContract) {
      onStepChange(currentStep + 1);
    }
  }, [currentStep, onStepChange, selectedContract]);

  const checkinController = usePerformCheckin({
    onSuccess: ({ customerName }) => navigation.push(`/thank/${customerName}`),
  });

  const handleCanvasAsBase64 = (canvas: HTMLCanvasElement): Promise<string> => {
    return new Promise((resolve, reject) => {
      if (canvas) {
        const context = canvas.getContext('2d');

        if (context) {
          const dataURL = context.canvas.toDataURL();
          return resolve(dataURL);
        }
      } else {
        return reject(new Error());
      }
    });
  };

  const handleStepChange = async (step: number) => {
    if (step === 0 && currentStep > step && selectedContract) {
      setSelectedContract(-1);
    }

    if (step === 3 && currentStep < step && signatureCanvas) {
      const isEmpty = await handleVerifySignature(signatureCanvas);

      if (isEmpty) {
        setCanSign(false);
        setTimeout(() => setCanSign(true), 3000);
      } else {
        setIsLoading(true);

        const base64 = await handleCanvasAsBase64(signatureCanvas);
        if (!base64) return;

        setSignature(base64);

        const signed = await signContract(base64);
        if (signed) onStepChange(step);
        setIsLoading(false);
      }
    } else {
      onStepChange(step);
    }
  };

  const handleVerifySignature = (canvas: HTMLCanvasElement): boolean => {
    if (canvas) {
      blankCanvas.width = canvas.width;
      blankCanvas.height = canvas.height;

      return canvas.toDataURL() === blankCanvas.toDataURL();
    } else {
      return false;
    }
  };

  const actionsForStep = (step: number) => {
    let actions: Array<TWizardAction> = [];

    if (step === 0) {
      actions = [
        {
          label: 'components.wizard.buttons.exit',
          disabled: () => false,
          onClick: () => navigation.push('/'),
        },
        {
          label: 'components.wizard.buttons.next',
          primary: true,
          disabled: () => selectedContract === undefined,
          onClick: () => handleStepChange(currentStep + 1),
        },
      ];
    }
    if (step === 1) {
      actions = [
        {
          label: 'components.wizard.buttons.exit',
          disabled: () => false,
          onClick: () => navigation.push('/'),
        },
        {
          label: 'pages.contracts.detail.actions.terms',
          disabled: () => false,
          onClick: () => setShowTerms(true),
        },
        {
          label: 'components.wizard.buttons.next',
          primary: true,
          disabled: () => !termsAccepted,
          onClick: () => handleStepChange(currentStep + 1),
        },
      ];
    }
    if (step === 2) {
      actions = [
        {
          label: 'components.wizard.buttons.previous',
          disabled: () => false,
          onClick: () => handleStepChange(currentStep - 1),
        },
        {
          label: 'pages.contracts.signing.actions.clear',
          disabled: () => false,
          onClick: () => signatureClearCallback && signatureClearCallback(),
        },
        {
          label: 'components.wizard.buttons.next',
          primary: true,
          loading: isLoading,
          disabled: () => !canSign,
          onClick: () => handleStepChange(currentStep + 1),
          tooltipText: 'Sua assinatura é obrigatória!',
        },
      ];
    }
    if (step === 3) {
      if (hasCheckin) {
        if (userContracts.length > 0) {
          actions = [
            {
              label: 'pages.contracts.finish.actions.home',
              disabled: () => false,
              onClick: () => navigation.push('/'),
            },
            {
              label: 'pages.contracts.finish.actions.sign_another',
              primary: true,
              disabled: () => false,
              onClick: () => handleStepChange(0),
            },
          ];
        } else {
          actions = [
            {
              label: 'pages.contracts.finish.actions.home',
              disabled: () => false,
              onClick: () => navigation.push('/'),
            },
            {
              label: 'pages.contracts.finish.actions.checkin',
              primary: true,
              disabled: () => false,
              onClick: async () =>
                checkinController.checkin({ customerId: userId }),
            },
          ];
        }
      } else {
        actions = [
          {
            label: 'pages.contracts.finish.actions.home',
            disabled: () => false,
            onClick: () => navigation.push('/'),
          },
          {
            label: 'pages.contracts.finish.actions.sign_another',
            disabled: () => false,
            onClick: () => handleStepChange(0),
          },
          {
            label: 'pages.contracts.finish.actions.schedule',
            primary: true,
            disabled: () => false,
            onClick: () => navigation.push('/schedules'),
          },
        ];
      }
    }

    return actions;
  };

  const steps = useMemo(() => {
    const selectContract = (constracId: number) => {
      setSelectedContract(constracId);
    };

    return [
      {
        title: t('pages.contracts.wizard_header.select_contract'),
        first: true,
        content: () => {
          return (
            <ContractSelectionList
              clientId={userId}
              onSelect={selectContract}
            />
          );
        },
      },
      {
        title: t('pages.contracts.wizard_header.contract_details'),
        content: () => {
          if (selectedContract) {
            return (
              <Details
                clientId={userId}
                termsChecked={termsAccepted}
                onTermsChecked={setTermsAccepted}
                contractId={selectedContract.id}
                showTerms={showTerms}
                setShowTerms={setShowTerms}
              />
            );
          }

          return <></>;
        },
      },
      {
        title: t('pages.contracts.wizard_header.sign'),
        content: () => (
          <Sign
            clearRef={setSignatureClearCallback}
            signatureCanvasRef={setSignatureCanvas}
          />
        ),
      },
      {
        title: t('pages.contracts.wizard_header.end'),
        last: true,
        content: () => (
          <Signed setWizardStep={onStepChange} contract={selectedContract} />
        ),
      },
    ];
  }, [
    setSelectedContract,
    userId,
    selectedContract,
    termsAccepted,
    setTermsAccepted,
    onStepChange,
    showTerms,
    t,
  ]);

  return (
    <Wizard
      step={currentStep}
      steps={steps}
      actions={actionsForStep(currentStep)}
    />
  );
};

export default ContractsWizard;
