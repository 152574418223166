import { useCallback, useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';

import Logo from 'assets/svgs/Logo_Vialaser.svg';
import { makeHTTPProvider } from 'shared/infra/providers';
import { ClockTimer } from 'shared/presentation/components/atoms';
import {
  Redirect,
  useActiveCustomer,
  useLocale,
  useNavigation,
} from 'shared/presentation/contexts';

import ScheduleCard from './ScheduleCard';
import * as S from './styles';
import logo from '../../assets/svgs/Logo_Vialaser.svg';
import Button from '../../components/button';
import MultiCarousel from '../../components/MultiCarousel';
import theme from '../../styles/theme';
import {
  TSchedule,
  TScheduleArea,
  TScheduleAreaResponse,
  TScheduleResponse,
} from '../../types/api';
import Logger from '../../utils/Logger';

const UserSchedules: React.FC = () => {
  const navigation = useNavigation();
  const { t } = useLocale('translations');
  const [loading, setLoading] = useState(true);
  const [schedules, setSchedules] = useState<TSchedule[]>([]);

  const { customer } = useActiveCustomer();

  const handleTimeUp = useCallback(() => navigation.push('/'), [navigation]);

  const getGender = (gender: string) => {
    if (gender) {
      return gender === 'UNISEX' ? 'feminino' : gender.toLowerCase();
    }

    return 'feminino';
  };

  useEffect(() => {
    const mapAreas = (
      sexo: string,
      areas: TScheduleAreaResponse[],
    ): TScheduleArea[] => {
      return areas.map(area => {
        if (JSON.stringify(area.imagens[getGender(sexo)]) === undefined) {
          const mappedArea: TScheduleArea = {
            id: area.id,
            name: area.nome,
            session: `${area.sessaoCorrente}/${area.totalSessoes}`,
            image: Logo,
          };

          return mappedArea;
        } else {
          const mappedArea: TScheduleArea = {
            id: area.id,
            name: area.nome,
            session: `${area.sessaoCorrente}/${area.totalSessoes}`,
            image: area.imagens[getGender(sexo)].baixa,
          };

          return mappedArea;
        }
      });
    };

    const mapSchedules = (
      schedulesResponse: TScheduleResponse[],
    ): TSchedule[] => {
      return schedulesResponse.map(schedule => {
        const mappedSchedule: TSchedule = {
          id: schedule.id,
          date: schedule.data,
          unit: schedule.unidade,
          location: schedule.enderecoUnidade,
          hour: schedule.hora,
          areas: mapAreas(schedule.sexo, schedule.areas),
        };

        return mappedSchedule;
      });
    };

    const getHistoricSchedule = async () => {
      try {
        if (!customer) {
          return;
        }

        const id = customer.id;
        const data = await makeHTTPProvider().get<TScheduleResponse[]>(
          `area-cliente/agendamentos/futuros/${id}`,
        );

        if (data) {
          setSchedules(mapSchedules(data));
        }

        setLoading(false);
      } catch (error) {
        Logger.error(error);
        setLoading(false);
      }
    };

    getHistoricSchedule();
  }, [customer]);

  const renderNoSchedules = () => {
    return <S.NoSchedules>{t('pages.schedules.noSchedules')}</S.NoSchedules>;
  };

  const renderSchedules = () => {
    return (
      <S.Schedules>
        <MultiCarousel slidesToShow={2}>
          {schedules.map(schedule => (
            <ScheduleCard
              key={schedule.id}
              schedule={schedule}
              onCancel={cancelSchedule}
            />
          ))}
        </MultiCarousel>
      </S.Schedules>
    );
  };

  const backCheckin = (): void => {
    return navigation.push('/');
  };

  const newSchedule = (): void => {
    return navigation.push('/schedules');
  };

  const cancelSchedule = async (id: number) => {
    setLoading(true);
    try {
      await makeHTTPProvider().post(`/agendas/${id}/cancelamento/`, {
        motivo: 'Cancelado pelo usuário',
        origem: 'TOTEM',
      });
      setSchedules(schedules.filter(schedule => schedule.id !== id));
      setLoading(false);
    } catch (error) {
      Logger.error(error);
      setLoading(false);
    }
  };

  const renderActions = () => {
    return (
      <S.Actions>
        <Button
          bordercolor={theme.colors.primary}
          onClick={() => backCheckin()}
        >
          {t('pages.schedules.back')}
        </Button>
        <Button
          color={theme.colors.white}
          background={theme.colors.primary}
          onClick={() => newSchedule()}
        >
          {t('pages.schedules.newSchedule')}
        </Button>
      </S.Actions>
    );
  };

  if (!customer) {
    return <Redirect to="/" params={{}} />;
  }

  const renderContentSchedule = () => {
    if (loading) {
      return (
        <S.Loading>
          <CircularProgress />
        </S.Loading>
      );
    }

    if (schedules.length > 0) {
      return renderSchedules();
    }

    return renderNoSchedules();
  };

  return (
    <S.Container>
      <S.Header>
        <S.Logo>
          <img src={Logo} alt={logo} />
        </S.Logo>

        <ClockTimer onTimeUp={handleTimeUp} />
      </S.Header>
      <S.Title>{t('pages.schedules.title')}</S.Title>
      {renderContentSchedule()}
      {renderActions()}
    </S.Container>
  );
};

export default UserSchedules;
