import { useState, useEffect } from 'react';

import SimpleModal from 'components/modal/variations/simple';
import ScheduleSuggestionModal from 'components/ScheduleSuggestionModal';
import DayPeriodSelector from 'components/Scheduling/DayPeriodSelector';
import HourSelector from 'components/Scheduling/HourSelector';
import ScheduleCalendar from 'components/Scheduling/ScheduleCalendar';
import ScheduleFinalize from 'components/Scheduling/ScheduleFinalize';
import ScheduleRegions from 'components/Scheduling/ScheduleRegions';
import Wizard, { TWizardAction, IWizardStep } from 'components/Wizard';
import { useScheduling } from 'context/SchedulingContext';
import Tracker from 'services/tracker';
import { TScheduleSuggestion } from 'types/api';
import { formatDate } from 'utils/functions';

import { makeHTTPProvider } from 'shared/infra/providers';
import {
  useActiveCustomer,
  useLocale,
  useNavigation,
  useUnit,
} from 'shared/presentation/contexts';

import * as S from './styles';

const SchedulingWizard: React.FC = () => {
  const {
    selectedDate,
    selectedHour,
    availableAreas,
    selectedAreas,
    setSelectedAreas,
    newSchedule,
    error,
    setError,
    setSelectedDate,
    setSelectedHour,
    minDate,
    sessionDuration,
  } = useScheduling();
  const { t } = useLocale('translations');
  const { customer } = useActiveCustomer();
  const { unit } = useUnit();
  const navigation = useNavigation();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [showHourWrapperAnimation, setShowHourWrapperAnimation] =
    useState<boolean>(false);

  const [showScheduleSuggestions, setShowScheduleSuggestions] =
    useState<boolean>(false);

  const [scheduleSuggestions, setScheduleSuggestions] = useState<
    Array<TScheduleSuggestion>
  >([]);

  const canFinishScheduling = () =>
    selectedDate !== undefined && selectedHour !== undefined;

  const handleStepChange = (step: number) => {
    if (canChangeStep(step)) {
      setCurrentStep(step);
    }
  };

  const actionsForStep = (step: number): TWizardAction[] => {
    const selectedAreasCount = Object.values(selectedAreas).filter(
      it => it,
    ).length;

    switch (step) {
      case 0:
        return availableAreas.length
          ? [
              {
                label: 'components.wizard.buttons.exit',
                disabled: () => false,
                onClick: () => navigation.push('/'),
              },
              {
                label:
                  selectedAreasCount === availableAreas.length
                    ? 'components.wizard.buttons.unselect_all'
                    : 'components.wizard.buttons.select_all',
                onClick: () => {
                  const areas = availableAreas.filter(
                    packt => !packt.blockingScheduleMessage,
                  );

                  if (selectedAreasCount === areas.length) {
                    setSelectedAreas({});
                  } else {
                    setSelectedAreas(
                      areas.reduce(
                        (acc, it) => ({
                          ...acc,
                          [it.id]: true,
                        }),
                        {},
                      ),
                    );
                  }
                },
              },
              {
                label: 'components.wizard.buttons.next',
                primary: true,
                key: 'Next',
                disabled: () => Object.values(selectedAreas).length === 0,
                onClick: () => handleStepChange(currentStep + 1),
              },
            ]
          : [];
      case 1:
        return [
          {
            label: 'components.wizard.buttons.previous',
            disabled: () => false,
            onClick: () => handleStepChange(currentStep - 1),
          },
          {
            label: 'pages.schedules.finishSchedule',
            primary: true,
            key: 'Next',
            tooltipText: t(
              'components.wizard.buttons.select_day_hour',
            ) as string,
            disabled: () => !canFinishScheduling(),
            onClick: () =>
              newSchedule(() => {
                handleStepChange(currentStep + 1);
                Tracker.trackAction(
                  Number(customer?.id),
                  'agendamentoConseguiuAgendar',
                );
              }),
          },
        ];
      case 2:
        return [
          {
            label: 'components.wizard.buttons.scheduleContinue',
            disabled: () => false,
            onClick: () => handleStepChange(0), // TODO: Reiniciar estado do schedulingWizard
          },
          {
            label: 'components.wizard.buttons.finish',
            primary: true,
            disabled: () => false,
            onClick: () => navigation.push('/'),
          },
        ];
      default:
        return [];
    }
  };

  const canChangeStep = (step: number) => {
    const selectedAreasCount = Object.values(selectedAreas).filter(
      it => it,
    ).length;

    if (step === 1 && selectedAreasCount === 0) {
      return false;
    }
    return !(
      step === 2 &&
      (selectedAreasCount === 0 || !selectedDate || !selectedHour)
    );
  };

  const handleHourWrapperAnimation = () => {
    setShowHourWrapperAnimation(true);
    setTimeout(() => {
      setShowHourWrapperAnimation(false);
    }, 1000);
  };

  const AreasAgendamentoStepMemo: IWizardStep = {
    title: t('pages.schedules.areasToSchedule'),
    content: () => <ScheduleRegions />,
  };

  const AgendamentoStepMemo = {
    title: t('pages.schedules.schedule'),
    nextLabel: t('pages.schedules.finishSchedule'),
    content: () => (
      <S.SchedulingContainer>
        <S.DayPeriodWrapper>
          <DayPeriodSelector />
        </S.DayPeriodWrapper>

        <S.CalendarWrapper onClick={handleHourWrapperAnimation}>
          <ScheduleCalendar />
        </S.CalendarWrapper>

        <S.HourSelectorWrapper active={showHourWrapperAnimation}>
          <HourSelector />
        </S.HourSelectorWrapper>

        <SimpleModal
          title={t('components.modal.attention')}
          subtitle=""
          content={error}
          open={error !== ''}
          onClose={() => setError('')}
        />
      </S.SchedulingContainer>
    ),
  };

  const FinalizarStepMemo = {
    title: t('components.wizard.buttons.finish'),
    content: () => <ScheduleFinalize />,
  };

  const wizardSteps: IWizardStep[] = [
    AreasAgendamentoStepMemo,
    AgendamentoStepMemo,
    FinalizarStepMemo,
  ];

  const handleFinishScheduleSelection = () => {
    setShowScheduleSuggestions(false);
    newSchedule(() => {
      setCurrentStep(currentStep + 1);
      Tracker.trackAction(
        Number(customer?.id),
        'agendamentoOptouPorDataSugerida',
      );
    });
  };

  const handleSelectScheduleSuggestion = (currentIndex: number) => {
    const schedules: Array<any> = [...scheduleSuggestions];
    setScheduleSuggestions(
      schedules.map((schedule, index) => {
        if (currentIndex === index) {
          schedule.selecionado = !schedule.selecionado;
          setSelectedDate(!schedule.selecionado ? null : schedule.data);
          setSelectedHour(!schedule.selecionado ? null : schedule.horario);
        } else {
          schedule.selecionado = false;
        }

        return schedule;
      }),
    );
  };

  const handleGetScheduleSuggestions = async () => {
    if (!unit) return;

    let date = new Date();

    const month = date.getMonth() + 7;
    const year = date.getFullYear();

    date = new Date(year, month, 0);
    const limitDate = formatDate(date.toISOString().split('T')[0]);

    const resp = await makeHTTPProvider().get<TScheduleSuggestion[]>(`
			blip/sugestaoDataAgendamento
			?id_cliente=${customer?.id}
			&id_unidade=${unit.id}
			&intervalo_duracao=${sessionDuration.minutes()}
			&data_base=${minDate.format('DD/MM/YYYY')}
			&data_limite=${limitDate}
		`);

    if (resp.length > 0) {
      const schedules = resp.map(schedule => {
        schedule.selecionado = false;
        schedule.data = formatDate(schedule.data, 'YYYY-MM-DD');
        return schedule;
      });

      setScheduleSuggestions(schedules);
      setShowScheduleSuggestions(true);
    }
  };

  useEffect(() => {
    if (currentStep === 1) {
      handleGetScheduleSuggestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    Tracker.trackAction(Number(customer?.id), 'agendamentoIniciou');
  }, [customer]);

  return (
    <>
      <Wizard
        step={currentStep}
        steps={wizardSteps}
        actions={actionsForStep(currentStep)}
      />

      {showScheduleSuggestions && scheduleSuggestions.length > 0 && (
        <ScheduleSuggestionModal
          scheduleSuggestions={scheduleSuggestions}
          onSelectSchedule={handleSelectScheduleSuggestion}
          onFinish={handleFinishScheduleSelection}
          onClose={() => setShowScheduleSuggestions(false)}
        />
      )}
    </>
  );
};

export default SchedulingWizard;
