import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    border-radius: 2rem;
    padding: ${theme.layout.spacing(4)};
    width: ${theme.layout.spacing(75)};

    background-color: ${theme.palette.neutral[50]};

    ${theme.layout.columnGap(5)}

    text-align: center;

    header {
      h1 {
        color: ${theme.palette.error.main};
        font-weight: ${theme.typography.weight.bold};
        font-size: ${theme.typography.sizes.title2};
      }
    }

    p {
      color: ${theme.palette.text.light};
      line-height: 150%;
      font-size: ${theme.typography.sizes.body1};
    }

    footer {
      display: flex;
      flex-direction: column;
      ${theme.layout.columnGap(3)}
    }
  `}
`;
