import { useMutation } from 'react-query';

import { makeFetchCustomerByDocumentUseCase } from 'modules/checkin/domain/useCases';
import { ICustomer } from 'shared/domain/entities';

type TParams = {
  onError?: (error: unknown) => void;
  onSuccess?: (customer: ICustomer | null) => void;
};

export default function useFindCustomerByDocument(params: TParams = {}) {
  const mutation = useMutation(
    (document: string) =>
      makeFetchCustomerByDocumentUseCase().execute({ document }),
    { onSuccess: params.onSuccess, onError: params.onError },
  );

  return {
    loading: mutation.isLoading,
    find: mutation.mutateAsync,
  };
}
