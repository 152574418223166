import {
  ComponentRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTheme } from '@material-ui/core';
import Button from 'components/button';
import NumberPad from 'components/number-pad';
import { useModal } from 'context/ModalContext';
import { ModalTypes } from 'enums/ModalTypes';
import { handleError } from 'helpers/handleError';
import {
  useCheckDataConfirmation,
  useBiometryFailure,
  useFindCustomerById,
  useFindCustomerByDocument,
  useCheckSunExposureQuestion,
  useCheckForIncidences,
} from 'pages/Checkin/hooks';
import { FiCalendar, FiClock, FiAlertCircle } from 'react-icons/fi';
import { QRCode } from 'react-qrcode-logo';

import LisaChatBubblePy from 'assets/images/lisa-balao-py.png';
import LisaCheckin from 'assets/images/lisa-convida.png';
import LisaChatBubble from 'assets/images/tela-totem-digital-baloon-text.png';
import DigitalIcon from 'assets/images/tela-totem-digital-icon.png';
import Icon from 'assets/logo.png';
import Receipt from 'assets/svgs/receipt.svg';
import { ReactComponent as TouchIconGreen } from 'assets/svgs/touch-icon-green.svg';
import { makeHashProvider } from 'shared/infra/providers';
import { makeCheckinPubSub } from 'shared/infra/pubsubs';
import {
  useLocale,
  useUnit,
  useTotemInfo,
  useNavigation,
  TRoutePathsWithParams,
  useActiveCustomer,
} from 'shared/presentation/contexts';
import { usePerformCheckin } from 'shared/presentation/hooks';

import {
  CheckSunExposureModal,
  ConfirmDataModal,
  IncidencesModal,
  NoBiometryAlert,
  UpdateDataModal,
} from './components';
import * as S from './styles';

const Checkin: React.FC = () => {
  const theme = useTheme();
  const navigation = useNavigation();
  const { showModal, handleClose } = useModal();
  const { version } = useTotemInfo();
  const { unit } = useUnit();
  const { t, language } = useLocale('translations');

  const { setCustomer } = useActiveCustomer();

  const confirmDataRef = useRef<ComponentRef<typeof ConfirmDataModal>>(null);
  const updateDataRef = useRef<ComponentRef<typeof UpdateDataModal>>(null);
  const biometryAlertRef = useRef<ComponentRef<typeof NoBiometryAlert>>(null);
  const sunExposureModalRef =
    useRef<ComponentRef<typeof CheckSunExposureModal>>(null);
  const incidencesModalRef = useRef<ComponentRef<typeof IncidencesModal>>(null);

  const [doingCpfCheckin, setDoingCpfCheckin] = useState(false);

  const [checkInError, setCheckInError] = useState('');
  const [checkAttempts, setCheckAttempts] = useState(0);

  const [doingLogin, setDoingLogin] = useState<boolean>(false);
  const [loginAction, setLoginAction] = useState<TRoutePathsWithParams>('/');
  const [checkinAction, setCheckinAction] =
    useState<TRoutePathsWithParams | null>(null);

  const increaseCheckinAttempt = useCallback(
    (biometry = false) => {
      const nextAttempt = checkAttempts + 1;

      setCheckAttempts(nextAttempt);

      if (nextAttempt === 1 && biometry) {
        showModal({ type: 'BIOMETRIA' });
        return;
      }
      if (nextAttempt > 1) {
        cpfValidationControl();
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkAttempts, showModal],
  );

  const handleTreatableError = useCallback(
    (error: unknown) => {
      setTimeout(() => handleClose(), 10000);
      const { message, type, response } = handleError(error);

      if (type && Object.values(ModalTypes).includes(type as ModalTypes)) {
        showModal({ message, type, response });
      } else {
        setCheckInError(message);
      }

      increaseCheckinAttempt();
    },
    [handleClose, increaseCheckinAttempt, showModal],
  );

  const confirmDataController = useCheckDataConfirmation();
  const checkSunExposureController = useCheckSunExposureQuestion();
  const checkIncidencesController = useCheckForIncidences();
  const checkinController = usePerformCheckin({
    onSuccess: ({ customerName }) => {
      handleClose();
      setDoingCpfCheckin(false);

      navigation.push(
        loginAction !== '/' ? loginAction : `/thank/${customerName}`,
      );
    },
    onError: error => handleTreatableError(error),
  });

  const findByIdController = useFindCustomerById({
    onSuccess: customer => {
      if (customer) return handleClose();

      setCheckInError(t('pages.checkin.identification_error'));
    },
    onError: error => handleTreatableError(error),
  });
  const findByDocumentController = useFindCustomerByDocument({
    onSuccess: customer => {
      if (customer) return handleClose();

      setCheckInError(t('pages.checkin.identification_error'));
    },
    onError: error => handleTreatableError(error),
  });

  const isDataConfirmationOnCheckinAvailable = useMemo(
    () => !!version && version !== 'legacy',
    [version],
  );

  useBiometryFailure({
    onFailedTwice: () => {
      setDoingCpfCheckin(true);
    },
  });

  const callButtonAction = (action: TRoutePathsWithParams) => {
    setDoingLogin(true);
    setLoginAction(action);
    showModal({
      type: ModalTypes.IDENTIFY,
      action: () => {
        setCheckInError('');
        setDoingCpfCheckin(true);
      },
    });
  };

  const schedule = (): void => {
    callButtonAction('/schedules');
  };

  const appointments = (): void => {
    callButtonAction('/user-schedules');
  };

  const signContract = (): void => {
    callButtonAction('/contracts');
  };

  const handleCheckinWithId = useCallback(
    async (id: number) => {
      if (!unit) return;

      if (!doingLogin) {
        return checkinController.checkin({ customerId: id });
      }

      const customer = await findByIdController.find(id);

      setCustomer(customer);

      if (customer) {
        navigation.push(loginAction);
      }
    },
    [
      navigation,
      unit,
      checkinController,
      setCustomer,
      doingLogin,
      findByIdController,
      loginAction,
    ],
  );

  const handleCheckIfHasIncidencesById = useCallback(
    async (customerId: number) => {
      if (doingLogin) return handleCheckinWithId(customerId);

      const shouldShowIncidences =
        await checkIncidencesController.shouldQuestionAboutIncidences({
          id: customerId,
        });

      if (!shouldShowIncidences) {
        return handleCheckinWithId(customerId);
      }

      incidencesModalRef.current?.open();
    },
    [doingLogin, handleCheckinWithId, checkIncidencesController],
  );

  const handleCheckIfHasSunExposureById = useCallback(
    async (customerId: number) => {
      if (doingLogin) return handleCheckinWithId(customerId);

      const shouldQuestionAboutSunExposure =
        await checkSunExposureController.shouldQuestionAboutSunExposure({
          id: customerId,
        });

      if (!shouldQuestionAboutSunExposure) {
        return handleCheckIfHasIncidencesById(customerId);
      }

      sunExposureModalRef.current?.open();
    },
    [
      doingLogin,
      handleCheckinWithId,
      handleCheckIfHasIncidencesById,
      checkSunExposureController,
    ],
  );

  const handleCheckinWithCpf = useCallback(
    async (value: string) => {
      if (!unit) return;

      if (!doingLogin) {
        return checkinController.checkin({
          customerDocument: value,
        });
      }

      const customer = await findByDocumentController.find(value);
      setCustomer(customer);

      if (customer) {
        navigation.push(loginAction);
      }
    },
    [
      checkinController,
      findByDocumentController,
      doingLogin,
      navigation,
      setCustomer,
      loginAction,
      unit,
    ],
  );

  const handleCheckIfHasIncidencesByDocument = useCallback(
    async (document: string) => {
      if (doingLogin) return handleCheckinWithCpf(document);

      const shouldShowIncidences =
        await checkIncidencesController.shouldQuestionAboutIncidences({
          document,
        });

      if (!shouldShowIncidences) {
        return handleCheckinWithCpf(document);
      }

      incidencesModalRef.current?.open();
    },
    [doingLogin, handleCheckinWithCpf, checkIncidencesController],
  );

  const handleCheckIfHasSunExposureByDocument = useCallback(
    async (document: string) => {
      if (doingLogin) return handleCheckinWithCpf(document);

      const shouldQuestionAboutSunExposure =
        await checkSunExposureController.shouldQuestionAboutSunExposure({
          document,
        });

      if (!shouldQuestionAboutSunExposure) {
        return handleCheckIfHasIncidencesByDocument(document);
      }

      sunExposureModalRef.current?.open();
    },
    [
      doingLogin,
      handleCheckinWithCpf,
      handleCheckIfHasIncidencesByDocument,
      checkSunExposureController,
    ],
  );

  useEffect(() => {
    const checkinPubSub = makeCheckinPubSub({ type: version });

    const unsubscribe = checkinPubSub.subscribeForIdentification(
      async ({ customerId }) => {
        if (!doingLogin) {
          setCheckinAction(null);
        }

        if (doingLogin || !isDataConfirmationOnCheckinAvailable) {
          return handleCheckIfHasSunExposureById(customerId);
        }

        const { isConfirmed, hasBiometry } =
          await confirmDataController.isDataConfirmed({
            id: customerId,
          });

        if (isConfirmed) {
          return handleCheckIfHasSunExposureById(customerId);
        }

        if (!hasBiometry) return biometryAlertRef.current?.open();

        confirmDataRef.current?.open();
      },
    );

    return () => {
      unsubscribe();
    };
  }, [
    version,
    confirmDataController,
    isDataConfirmationOnCheckinAvailable,
    doingLogin,
    handleCheckIfHasSunExposureById,
  ]);

  const cpfValidationControl = () => {
    setCheckAttempts(0);
    setCheckInError('');
    setDoingCpfCheckin(true);
  };

  const handleConfirmDataFromDocument = async (document: string) => {
    if (doingLogin || !isDataConfirmationOnCheckinAvailable) {
      return handleCheckIfHasSunExposureByDocument(document);
    }

    const { isConfirmed, hasBiometry } =
      await confirmDataController.isDataConfirmed({
        document,
      });

    if (isConfirmed) {
      return handleCheckIfHasSunExposureByDocument(document);
    }

    setDoingCpfCheckin(false);

    if (!hasBiometry) return biometryAlertRef.current?.open();

    confirmDataRef.current?.open();
  };

  const getUrlQrCode = useMemo(() => {
    if (!unit) {
      return;
    }

    const hash = makeHashProvider().encode({ id: unit.id, unit: unit.name });

    return `${window.location.origin}/qrcode-checkin/${hash}`;
  }, [unit]);

  const isSpanishLanguage = language === 'es' || language === 'es-CR';

  return (
    <S.Checkin id="checkin-container">
      <S.Main>
        <img id="lisa" src={LisaCheckin} alt="lisa convida" />
        <S.Fingerprint src={DigitalIcon} alt="digitalIcon" />

        <S.LisaPopup>
          <img
            src={isSpanishLanguage ? LisaChatBubblePy : LisaChatBubble}
            alt="chatBubble"
          />
        </S.LisaPopup>

        <S.Actions isSpanishLanguage={isSpanishLanguage}>
          {!!unit && (
            <QRCode
              logoImage={Icon}
              qrStyle="dots"
              value={getUrlQrCode}
              bgColor={theme.colors.background}
              fgColor={theme.colors.primary}
              size={200}
            />
          )}

          <S.ButtonTouch>
            <Button
              color={theme.colors.white}
              background={theme.colors.primary}
              onClick={() => {
                setCheckInError('');
                setDoingCpfCheckin(true);
                setCheckinAction(null);
              }}
            >
              {t('pages.home.actions.cpf_checkin')}{' '}
              {language === 'es-CR' && ' / DIMEX'}
            </Button>

            <TouchIconGreen />
          </S.ButtonTouch>
        </S.Actions>

        {doingCpfCheckin && (
          <NumberPad
            open={doingCpfCheckin}
            error={checkInError}
            onSubmit={
              doingLogin ? handleCheckinWithCpf : handleConfirmDataFromDocument
            }
            handleClose={() => {
              handleClose();
              setLoginAction('/');
              setDoingLogin(false);
              setDoingCpfCheckin(false);
              setCheckinAction(null);
            }}
          />
        )}
      </S.Main>

      <S.Menu>
        <Button
          bordercolor={theme.colors.primary}
          icon={<FiCalendar size={16} color={theme.palette.text.hint} />}
          onClick={schedule}
          dataCy="newScheduleButton"
        >
          {t('pages.home.actions.new_schedule')}
        </Button>

        <Button
          bordercolor={theme.colors.primary}
          icon={<FiClock size={16} color={theme.palette.text.hint} />}
          onClick={appointments}
        >
          {t('pages.home.actions.my_schedules')}
        </Button>

        <Button
          icon={<img src={Receipt} alt="icon" />}
          bordercolor={theme.colors.primary}
          onClick={() => signContract()}
        >
          {t('pages.home.actions.sign_contract')}
        </Button>

        {!unit && (
          <S.ErrorAlert>
            <FiAlertCircle />

            <span>{t('pages.checkin.error_message')}</span>
          </S.ErrorAlert>
        )}
      </S.Menu>

      {!!confirmDataController.customerData && (
        <>
          <ConfirmDataModal
            ref={confirmDataRef}
            customer={confirmDataController.customerData}
            onUpdate={() => {
              updateDataRef.current?.open();
            }}
            onConfirm={customer => handleCheckIfHasSunExposureById(customer.id)}
          />

          <UpdateDataModal
            ref={updateDataRef}
            customer={confirmDataController.customerData}
            onSuccess={async customer =>
              handleCheckIfHasSunExposureById(customer.id)
            }
          />
        </>
      )}

      {!!checkSunExposureController.questionData && (
        <CheckSunExposureModal
          ref={sunExposureModalRef}
          {...checkSunExposureController.questionData}
          onDoCheckin={({ customerId, to }) => {
            if (to) setLoginAction(to);
            handleCheckIfHasIncidencesById(customerId);
          }}
        />
      )}

      {!!checkIncidencesController.incidenceData && (
        <IncidencesModal
          ref={incidencesModalRef}
          {...checkIncidencesController.incidenceData}
          onSuccess={customer => handleCheckinWithId(customer.id)}
        />
      )}

      <NoBiometryAlert ref={biometryAlertRef} />
    </S.Checkin>
  );
};

export default Checkin;
