import { KeyboardLayoutObject } from 'react-simple-keyboard';

type TFilterableKeys = {
  '{enter}': boolean;
  '{bksp}': boolean;
};

export default function filterLayoutKeys(
  filterableKeys: TFilterableKeys,
  layout: KeyboardLayoutObject,
) {
  const keysToFilterOut = Object.keys(filterableKeys).filter(
    key => !filterableKeys[key],
  );

  if (!keysToFilterOut.length) return layout;

  const startRegexString = keysToFilterOut.map(key => `${key} `).join('|');
  const endRegexString = keysToFilterOut.map(key => ` ${key}`).join('|');
  const startReplaceRegex = new RegExp(startRegexString, 'g');
  const endReplaceRegex = new RegExp(endRegexString, 'g');

  return Object.entries(layout).reduce((acc, [variant, buttonsRow]) => {
    return {
      ...acc,
      [variant]: buttonsRow.map(buttons =>
        buttons.replace(startReplaceRegex, '').replace(endReplaceRegex, ''),
      ),
    };
  }, {} as KeyboardLayoutObject);
}
