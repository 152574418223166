import { lighten } from 'polished';
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

export type TButtonVariant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning';

interface IButtonProps {
  variant?: TButtonVariant;
  outline?: boolean;
  loading?: boolean;
}

const DEFAULT_BUTTON_VARIANTS: Record<
  TButtonVariant,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  default: css`
    ${({ theme }) => css`
      color: ${theme.palette.text.main};
      background: ${theme.palette.neutral[200]};

      :hover:not(:disabled) {
        background: ${theme.palette.neutral[300]};
      }

      :disabled {
        color: ${theme.palette.text.light};
      }
    `}
  `,

  primary: css`
    ${({ theme }) => css`
      color: ${theme.palette.text.white};
      background: ${theme.palette.primary.main};

      :hover:not(:disabled) {
        background: ${theme.palette.primary.dark};
      }
    `}
  `,

  secondary: css`
    ${({ theme }) => css`
      color: ${theme.palette.text.white};
      background: ${theme.palette.secondary.main};

      :hover:not(:disabled) {
        background: ${theme.palette.secondary.dark};
      }
    `}
  `,

  error: css`
    ${({ theme }) => css`
      color: ${theme.palette.text.white};
      background: ${theme.palette.error.main};

      :hover:not(:disabled) {
        background: ${theme.palette.error.dark};
      }
    `}
  `,

  warning: css`
    ${({ theme }) => css`
      color: ${theme.palette.text.white};
      background: ${theme.palette.warning.main};

      :hover:not(:disabled) {
        background: ${theme.palette.warning.dark};
      }
    `}
  `,
};

const OUTLINE_BUTTON_VARIANTS: Record<
  TButtonVariant,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  default: css`
    ${({ theme }) => css`
      color: ${theme.palette.text.main};
      background: transparent;
      border-color: ${theme.palette.neutral[200]};

      :hover:not(:disabled) {
        color: ${theme.palette.text.main};
        border-color: ${theme.palette.neutral[300]};
      }
    `}
  `,

  primary: css`
    ${({ theme }) => css`
      color: ${theme.palette.primary.main};
      background: transparent;
      border-color: ${theme.palette.primary.main};

      :hover:not(:disabled) {
        color: ${theme.palette.primary.dark};
        border-color: ${theme.palette.primary.dark};
      }
    `}
  `,

  secondary: css`
    ${({ theme }) => css`
      color: ${theme.palette.secondary.main};
      background: transparent;
      border-color: ${theme.palette.secondary.main};

      :hover:not(:disabled) {
        color: ${theme.palette.secondary.dark};
        border-color: ${theme.palette.secondary.dark};
      }
    `}
  `,

  error: css`
    ${({ theme }) => css`
      color: ${theme.palette.error.main};
      background: transparent;
      border-color: ${theme.palette.error.main};

      :hover:not(:disabled) {
        color: ${theme.palette.error.dark};
        border-color: ${theme.palette.error.dark};
      }
    `}
  `,

  warning: css`
    ${({ theme }) => css`
      color: ${theme.palette.warning.main};
      background: transparent;
      border-color: ${theme.palette.warning.main};

      :hover:not(:disabled) {
        color: ${theme.palette.warning.dark};
        border-color: ${theme.palette.warning.dark};
      }
    `}
  `,
};

const DISABLED_STYLES = {
  DEFAULT: css`
    ${({ theme }) => css`
      color: ${theme.palette.text.white};
      background: ${theme.palette.neutral[400]};
      border-color: ${theme.palette.neutral[400]};
    `}
  `,
  OUTLINE: css`
    ${({ theme }) => css`
      color: ${theme.palette.neutral[400]};
      background: transparent;
      border-color: ${theme.palette.neutral[400]};
    `}
  `,
};

export const Button = styled.button<IButtonProps>`
  ${({ theme, outline, variant, loading }) => css`
    outline: none;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.layout.rowGap(2)}

    height: ${theme.layout.spacing(8)};
    border-radius: 1rem;
    flex-shrink: 0;
    padding: ${theme.layout.spacing(0, 2)};

    border: 1px solid #ffffff00;
    color: ${theme.palette.text.main};
    background-color: ${outline ? 'transparent' : theme.palette.neutral[50]};

    font-size: ${props => props.theme.typography.sizes.body1};
    font-weight: ${props => props.theme.typography.weight.bold};

    transition: 0.3s;

    ${!outline &&
    css`
      :hover:not(:disabled) {
        background-color: ${lighten(0.03, theme.palette.neutral[50])};
      }
    `}

    ${variant &&
    (outline
      ? OUTLINE_BUTTON_VARIANTS[variant]
      : DEFAULT_BUTTON_VARIANTS[variant])}

  :disabled {
      cursor: not-allowed;
      ${loading
        ? css`
            opacity: 0.8;
          `
        : outline
        ? DISABLED_STYLES.OUTLINE
        : DISABLED_STYLES.DEFAULT}
    }

    svg {
      font-size: 1.25rem;
    }
  `}
`;
