import { useCallback } from 'react';

import {
  Fade,
  Tooltip,
  CircularProgress,
  Step,
  StepLabel,
} from '@material-ui/core';
import * as S from 'pages/Schedules/SchedulingWizard/styles';
import theme from 'styles/theme';

import Logo from 'assets/svgs/Logo_Vialaser.svg';
import { ClockTimer } from 'shared/presentation/components/atoms';
import { useLocale, useNavigation } from 'shared/presentation/contexts';

import {
  WizardContainer,
  WizardContent,
  WizardFooter,
  WizardHeader,
  TooltipText,
  TopHeader,
} from './styles';
import { Button } from '../button/styles';

export interface IWizardStep {
  title: string;
  content: () => React.ReactElement;
  nextLabel?: string;
}

export type TWizardAction = {
  label: string;
  key?: string;
  tooltipText?: string;
  primary?: boolean;
  loading?: boolean;
  disabled?: (step: number) => boolean;
  onClick: () => void;
};

type TWizardProps = {
  step: number;
  steps: IWizardStep[];
  actions?: TWizardAction[];
};

export type TWizardStepProps = {
  setWizardStep: (step: number) => void;
};

const Wizard: React.FC<TWizardProps> = ({ step, steps, actions }) => {
  const navigation = useNavigation();
  const { t } = useLocale('translations');

  const handleTimeUp = useCallback(() => navigation.push('/'), [navigation]);

  const currentStep = steps[step];

  return (
    <WizardContainer>
      <TopHeader>
        <S.Logo>
          <img src={Logo} alt="Logo" />
        </S.Logo>

        <ClockTimer onTimeUp={handleTimeUp} />
      </TopHeader>

      <WizardHeader activeStep={step}>
        {steps.map((wizardStep, index) => (
          <Step key={wizardStep.title} completed={step > index}>
            <StepLabel>{t(wizardStep.title as any)}</StepLabel>
          </Step>
        ))}
      </WizardHeader>

      <WizardContent>
        <Fade style={{ width: '100%' }} in>
          {currentStep.content()}
        </Fade>
      </WizardContent>

      <WizardFooter>
        {actions &&
          actions.map(action => (
            <Tooltip
              key={action.key}
              arrow
              title={
                action.tooltipText ? (
                  <TooltipText>{action.tooltipText}</TooltipText>
                ) : (
                  ''
                )
              }
              open={action.disabled ? action.disabled(step) : false}
            >
              <Button
                data-cy={`wizardButton${action.key}`}
                color={action.primary ? '#fff' : theme.colors.primary}
                bordercolor={theme.colors.primary}
                key={action.label}
                disabled={action.disabled ? action.disabled(step) : false}
                onClick={action.onClick}
                background={
                  action.primary ? theme.colors.primary : 'transparent'
                }
              >
                {action.loading ? (
                  <CircularProgress color="inherit" size={18} />
                ) : (
                  t(action.label as any)
                )}
              </Button>
            </Tooltip>
          ))}
      </WizardFooter>
    </WizardContainer>
  );
};

export default Wizard;
