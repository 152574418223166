import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    ${theme.layout.columnGap(4)}
    padding: ${theme.layout.spacing(4)};

    width: 100vw;
    height: 100vh;

    overflow-y: auto;

    background-color: ${theme.palette.neutral[100]};

    color: ${theme.palette.text.main};

    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      ${theme.layout.columnGap(2)}

      h1 {
        color: ${theme.palette.primary.main};
        font-weight: ${theme.typography.weight.bold};
        font-size: ${theme.typography.sizes.title2};
      }

      p {
        color: ${theme.palette.text.light};
        line-height: 150%;
        font-size: ${theme.typography.sizes.body1};
      }
    }

    footer {
      display: flex;
      flex-direction: column;
      ${theme.layout.columnGap(2)}

      width: 100%;
      max-width: ${theme.layout.spacing(138)};
    }
  `}
`;
