import {
  ButtonHTMLAttributes,
  forwardRef,
  ForwardRefExoticComponent,
  ForwardRefRenderFunction,
  ReactNode,
  RefAttributes,
  useCallback,
  useState,
} from 'react';

import { Icon, LoadingSpinner } from './components';
import { ButtonContext } from './context';
import * as S from './styles';

type TDefaultButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

interface IButtonProps {
  disabled?: TDefaultButtonProps['disabled'];
  onClick?: TDefaultButtonProps['onClick'];
  variant?: S.TButtonVariant;
  loading?: boolean;
  outline?: boolean;
  hideTextWhenLoading?: boolean;
  className?: string;
  type?: TDefaultButtonProps['type'];
  children: ReactNode;
}

type TButton = ForwardRefExoticComponent<
  IButtonProps & RefAttributes<HTMLButtonElement>
> & {
  Icon: typeof Icon;
};

const Button: ForwardRefRenderFunction<HTMLButtonElement, IButtonProps> = (
  {
    disabled,
    onClick,
    variant,
    loading = false,
    hideTextWhenLoading,
    outline,
    className,
    type,
    children,
    ...rest
  },
  ref,
) => {
  const [iconNames, setIconNames] = useState<string[]>([]);

  const registerIcon = useCallback(
    (iconName: string) =>
      setIconNames(currentIconNames => [...currentIconNames, iconName]),
    [],
  );

  const unregisterIcon = useCallback(
    (iconName: string) =>
      setIconNames(currentIconNames =>
        currentIconNames.filter(icon => icon !== iconName),
      ),
    [],
  );

  const shouldReplaceChildrenWithSpinner = loading && hideTextWhenLoading;
  const shouldShowStandaloneSpinner = !iconNames.length && loading;

  return (
    <S.Button
      disabled={loading || disabled}
      onClick={onClick}
      variant={variant}
      className={className}
      type={type}
      outline={outline}
      ref={ref}
      {...rest}
    >
      <ButtonContext.Provider
        value={{
          loading,
          variant,
          outline,
          registerIcon,
          unregisterIcon,
        }}
      >
        {shouldReplaceChildrenWithSpinner ? (
          <LoadingSpinner />
        ) : (
          <>
            <span>{children}</span>

            {shouldShowStandaloneSpinner && <LoadingSpinner />}
          </>
        )}
      </ButtonContext.Provider>
    </S.Button>
  );
};

const BaseButton: TButton = forwardRef(Button) as TButton;
BaseButton.Icon = Icon;

BaseButton.displayName = 'Button';
BaseButton.Icon.displayName = 'Button.Icon';

export default BaseButton;
