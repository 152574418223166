import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    ${theme.layout.rowGap(1.5)}

    width: ${theme.layout.spacing(25)};

    > span {
      color: ${theme.palette.text.light};
      font-weight: ${theme.typography.weight.bold};
    }
  `}
`;

export const CircularProgress = styled.div<{ progress: number }>`
  ${({ theme, progress }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;

    transition: all 0.2s;

    width: ${theme.layout.spacing(5)};
    height: ${theme.layout.spacing(5)};
    border-radius: 50%;
    background: conic-gradient(
      ${theme.palette.primary.main} ${Math.round(progress)}%,
      transparent 0
    );
  `}
`;
