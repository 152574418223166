import {
  AiOutlineEllipsis,
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineTwitter,
  AiOutlineYoutube,
} from 'react-icons/ai';
import { BiArrowToLeft, BiArrowToRight, BiCheckDouble } from 'react-icons/bi';
import { FaFilePdf, FaHeart, FaWhatsapp } from 'react-icons/fa';
import * as Feather from 'react-icons/fi';
import { HiDocumentText, HiOutlineQrcode } from 'react-icons/hi';
import { MdDelete, MdPayments, MdRefresh } from 'react-icons/md';

export const ICONS = {
  FACEBOOK: AiOutlineFacebook,
  TWITTER: AiOutlineTwitter,
  YOUTUBE: AiOutlineYoutube,
  INSTAGRAM: AiOutlineInstagram,
  LINKEDIN: AiOutlineLinkedin,
  ELLIPSIS: AiOutlineEllipsis,

  DOUBLE_CHECK: BiCheckDouble,
  ARROW_FIRST: BiArrowToLeft,
  ARROW_LAST: BiArrowToRight,

  X: Feather.FiX,
  TAG: Feather.FiTag,
  KEY: Feather.FiKey,
  SUN: Feather.FiSun,
  MAP: Feather.FiMap,
  USER: Feather.FiUser,
  MAIL: Feather.FiMail,
  LOCK: Feather.FiLock,
  MOON: Feather.FiMoon,
  STAR: Feather.FiStar,
  COPY: Feather.FiCopy,
  HASH: Feather.FiHash,
  FLAG: Feather.FiFlag,
  PLUS: Feather.FiPlus,
  LINK: Feather.FiLink,
  FILE: Feather.FiFile,
  HOME: Feather.FiHome,
  MENU: Feather.FiMenu,
  BELL: Feather.FiBell,
  SEND: Feather.FiSend,
  EDIT: Feather.FiEdit3,
  TRUCK: Feather.FiTruck,
  USERS: Feather.FiUsers,
  MINUS: Feather.FiMinus,
  PHONE: Feather.FiPhone,
  CHECK: Feather.FiCheck,
  CIRCLE: Feather.FiCircle,
  IMAGE: Feather.FiImage,
  LOG_IN: Feather.FiLogIn,
  TRASH: Feather.FiTrash2,
  USER_X: Feather.FiUserX,
  SEARCH: Feather.FiSearch,
  UNLOCK: Feather.FiUnlock,
  SHIELD: Feather.FiShield,
  LOG_OUT: Feather.FiLogOut,
  MAP_PIN: Feather.FiMapPin,
  CARD: Feather.FiCreditCard,
  BAG: Feather.FiShoppingBag,
  PACKAGE: Feather.FiPackage,
  CALENDAR: Feather.FiCalendar,
  X_CIRCLE: Feather.FiXCircle,
  SETTINGS: Feather.FiSettings,
  ARROW_UP: Feather.FiArrowUp,
  FILE_TEXT: Feather.FiFileText,
  PERCENTAGE: Feather.FiPercent,
  CURRENCY: Feather.FiDollarSign,
  ARROW_LEFT: Feather.FiArrowLeft,
  OPTIONS: Feather.FiMoreVertical,
  CHEVRON_UP: Feather.FiChevronUp,
  MESSAGE: Feather.FiMessageSquare,
  ARROW_RIGHT: Feather.FiArrowRight,
  DOWNLOAD: Feather.FiDownloadCloud,
  ALERT_CIRCLE: Feather.FiAlertCircle,
  QUESTION_MARK: Feather.FiHelpCircle,
  CHEVRON_DOWN: Feather.FiChevronDown,
  CHEVRON_LEFT: Feather.FiChevronLeft,
  CHECK_CIRCLE: Feather.FiCheckCircle,
  SHOPPING_BAG: Feather.FiShoppingBag,
  SHOPPING_CART: Feather.FiShoppingCart,
  CHEVRON_RIGHT: Feather.FiChevronRight,
  ALERT_OCTAGON: Feather.FiAlertOctagon,
  EXTERNAL_LINK: Feather.FiExternalLink,
  ALERT_TRIANGLE: Feather.FiAlertTriangle,
  GIFT: Feather.FiGift,
  CLOCK: Feather.FiClock,
  SAVE: Feather.FiSave,
  MORE_VERTICAL: Feather.FiMoreVertical,

  HEART_FILL: FaHeart,
  WHATSAPP: FaWhatsapp,
  PDF: FaFilePdf,

  QRCODE: HiOutlineQrcode,
  DOCUMENT: HiDocumentText,

  DELETE: MdDelete,
  REFRESH: MdRefresh,
  PAYMENT: MdPayments,
};

type TIconName = keyof typeof ICONS;
export type TIconType = (typeof ICONS)[TIconName];
