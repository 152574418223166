import checkinModule from 'modules/checkin/presentation/checkin.module';
import sharedModule from 'shared/presentation/shared.module';

import { ModuleAggregator } from './aggregator';

type TModuleName = 'shared' | 'checkin';

const appModules = new ModuleAggregator<TModuleName>()
  .use('shared', sharedModule)
  .use('checkin', checkinModule)
  .build();

export default appModules;
