import React, { MouseEvent, Reducer, useEffect, useReducer } from 'react';

import Keyboard from 'react-simple-keyboard';

import { useLocale } from 'shared/presentation/contexts';

import { INITIAL_MODIFIER_VALUES } from './constants';
import {
  filterLayoutKeys,
  getCurrentLayoutName,
  getCurrentPressedButtonts,
} from './helpers';
import { LAYOUTS } from './layouts';
import * as S from './styles';
import { TActions, TLayoutNames, TModifierState } from './types';

import 'react-simple-keyboard/build/css/index.css';

interface IVirtualKeyboardProps {
  layout?: TLayoutNames;
  disabled?: boolean;
  onKeyPress(button: string): void;
  onEnterPress?: () => void;
  onBackspacePress?: () => void;
}

const VirtualKeyboard: React.FC<IVirtualKeyboardProps> = ({
  layout = 'DEFAULT',
  disabled,
  onKeyPress,
  onBackspacePress,
  onEnterPress,
}) => {
  const { t } = useLocale('shared');

  const [modifiers, toggleModifier] = useReducer<
    Reducer<TModifierState, TActions>
  >((state, action) => {
    if (action === 'reset') return INITIAL_MODIFIER_VALUES;

    return {
      ...state,
      [action]: !state[action],
    };
  }, INITIAL_MODIFIER_VALUES);

  useEffect(() => {
    toggleModifier('reset');
  }, [layout]);

  useEffect(() => {
    const buttons = document.querySelectorAll('.hg-button');

    const onButtonClick = (e: Event) => {
      const event = e as unknown as MouseEvent<HTMLButtonElement>;
      const target = e.target as HTMLButtonElement;

      const x = event.clientX - target.offsetLeft;
      const y = event.clientY - target.offsetTop;

      const ripple = document.createElement('span');
      ripple.className = 'ripple';
      ripple.style.left = x + 'px';
      ripple.style.top = y + 'px';
      target.appendChild(ripple);

      setTimeout(() => {
        ripple.remove();
      }, 1000);
    };

    buttons.forEach(button => button.addEventListener('click', onButtonClick));

    return () => {
      buttons.forEach(button =>
        button.removeEventListener('click', onButtonClick),
      );
    };
  }, [layout]);

  const handleKeyPress = (button: string) => {
    if (disabled) return;

    function handleToggle(action: TActions, reset?: boolean) {
      if (reset) toggleModifier('reset');

      return toggleModifier(action);
    }

    const strategies: Record<string, (() => void) | undefined> = {
      '{shift}': () => handleToggle('shift'),
      '{lock}': () => handleToggle('lock', true),
      '{numberPad}': () => handleToggle('numberPad', true),
      '{symbols}': () => handleToggle('symbols'),
      '{default}': () => handleToggle('reset'),
    };

    const strategy = strategies[button];
    if (strategy) return strategy();

    if (modifiers.shift) toggleModifier('shift');

    if (button === '{enter}' && !!onEnterPress) return onEnterPress();
    if (button === '{bksp}' && !!onBackspacePress) return onBackspacePress();
    if (button === '{space}') return onKeyPress(' ');
    onKeyPress(button);
  };

  const layoutName = getCurrentLayoutName(modifiers, layout);
  const pressedButtons = getCurrentPressedButtonts(modifiers);
  const keyboardLayout = filterLayoutKeys(
    { '{enter}': !!onEnterPress, '{bksp}': !!onBackspacePress },
    LAYOUTS[layout],
  );

  return (
    <S.Container disabled={disabled}>
      <Keyboard
        onKeyPress={handleKeyPress}
        preventMouseDownDefault
        disableCaretPositioning
        theme="hg-theme-default virtual-keyboard"
        buttonTheme={[
          { class: 'primary', buttons: '{enter}' },
          { class: 'pressed', buttons: pressedButtons },
          { class: 'capitalize', buttons: '{enter} {shift} {bksp}' },
        ]}
        layoutName={layoutName}
        layout={keyboardLayout}
        display={{
          '{enter}': 'enter',
          '{numberPad}': '?123',
          '{symbols}': '=\\<',
          '{default}': 'ABC',
          '{bksp}': t('components.virtual_keyboard.clear'),
        }}
        mergeDisplay
      />
    </S.Container>
  );
};

export default VirtualKeyboard;
